<template>
  <div class="all-ticketcnt">
    <header class="inner-header">
      <span class="icon-Btn goback">
        <back-icon />
      </span>
      <h1>All Ticket</h1>

      <div class="right-colbtn">
        <router-link class="button" tag="button" to="/create-ticket">
          <span class="icon-Btn">
            <plus-icon />
          </span>
          Create
        </router-link>
      </div>
    </header>
    <ul class="tab-type">
      <li
        @click="ticketTabStatus = 'open_ticket'"
        :class="ticketTabStatus === 'open_ticket' ? 'selected-tab' : ''"
      >
        Open (03)
      </li>
      <li
        @click="ticketTabStatus = 'close_ticket'"
        :class="ticketTabStatus === 'close_ticket' ? 'selected-tab' : ''"
      >
        Close (02)
      </li>
    </ul>
    <section class="mid-cnt">
      <open-ticket v-if="ticketTabStatus === 'open_ticket'" />
      <close-ticket v-if="ticketTabStatus === 'close_ticket'" />
    </section>
  </div>
</template>

<script>
import BackIcon from '@/components/SvgIcons/BackIcon';
import ArrowRightIcon from '@/components/SvgIcons/ArrowRightIcon';
import OpenTicket from '@/components/Ticket/OpenTicket';
import CloseTicket from '@/components/Ticket/CloseTicket';
import PlusIcon from '@/components/SvgIcons/PlusIcon';

export default {
  name: 'AllTicket',
  components: {
    BackIcon,
    ArrowRightIcon,
    OpenTicket,
    CloseTicket,
    PlusIcon,
  },
  data() {
    return {
      ticketTabStatus: 'open_ticket',
    };
  },
  method: {},
};
</script>

<style lang="scss">
@import '@/assets/scss/all-ticket.scss';
</style>
