<template>
  <div>
    <ul class="ticket-logs">
      <router-link tag="li" to="/ticket-chat-log-list">
        <div class="status-blk">
          <span class="badges-tag">Others</span>
          <span class="date-tag">19 Oct 2020</span>
        </div>
        <div class="ticket-id">
          <span>Ticket ID :</span> <span class="text-id">#458298</span>
        </div>
        <div class="ticket-qus">
          <p>15 days have passed since I haven't received my product yet</p>
          <span class="icon-Btn mark-icon">
            <arrow-right-icon />
          </span>
        </div>
      </router-link>

      <router-link tag="li" to="/ticket-chat-log-list">
        <div class="status-blk">
          <span class="badges-tag">Others</span>
          <span class="date-tag">19 Oct 2020</span>
        </div>
        <div class="ticket-id">
          <span>Ticket ID :</span> <span class="text-id">#458298</span>
        </div>
        <div class="ticket-qus">
          <p>15 days have passed since I haven't received my product yet</p>
          <span class="icon-Btn mark-icon">
            <arrow-right-icon />
          </span>
        </div>

        <div class="ticket-ans">
          <p>Nope you can only withdraw 160 rs max.</p>
          <span class="text-date">16 Oct 20</span>
        </div>
      </router-link>
    </ul>
  </div>
</template>

<script>
import ArrowRightIcon from '@/components/SvgIcons/ArrowRightIcon';

export default {
  name: 'OpenTicket',
  components: {
    ArrowRightIcon,
  },
  data() {
    return {};
  },
};
</script>
